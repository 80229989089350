import { CampaignTemplate } from '@/client/campaigns';
import { FlexContainer } from '@/ui/styled-ui';
import { Divider } from 'primereact/divider';
import React, { ReactNode } from 'react';
import { CampaignInfo } from './CampaignInfo';
import { EmailTemplatePreview } from './EmailTemplatePreview';

type CatalogueCampaignPropsType = {
  campaign: CampaignTemplate;
  actions: ReactNode;
  id: string;
};

export const CatalogueCampaign: React.FC<CatalogueCampaignPropsType> = ({
  campaign,
  actions,
  id,
}) => (
  <div id={id}>
    <FlexContainer align="stretch" minWidth="680px">
      <EmailTemplatePreview campaign={campaign} actions={actions} />
      <CampaignInfo campaign={campaign} className="catalogue" />
    </FlexContainer>
    <Divider className="my-4" />
  </div>
);
