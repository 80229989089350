import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { MessageResponseModel } from '../models';
import {
  CampaignTemplate,
  CloneCampaignRequest,
  ListCampaignsRequest,
  ListCampaignTemplatesResponse,
  SaveCampaignRequest,
  UpdateCampaignRequest,
} from './types';

export const campaignTemplatesService = (client: AxiosInstance) => {
  const CAMPAIGN_TEMPLATES_BASE_URL = '/gophish/campaign-templates';

  const getCampaignTemplates = async (
    params: ListCampaignsRequest,
  ): Promise<ListCampaignTemplatesResponse> => {
    try {
      const result = await client.get<
        ListCampaignTemplatesResponse,
        AxiosResponse<ListCampaignTemplatesResponse>
      >(CAMPAIGN_TEMPLATES_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCampaignTemplate = async (
    campaignId: string,
  ): Promise<CampaignTemplate> => {
    try {
      const result = await client.get<
        CampaignTemplate,
        AxiosResponse<CampaignTemplate>
      >(`${CAMPAIGN_TEMPLATES_BASE_URL}/${campaignId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateCampaignTemplate = async (
    request: UpdateCampaignRequest,
  ): Promise<CampaignTemplate> => {
    try {
      const result = await client.patch(
        `${CAMPAIGN_TEMPLATES_BASE_URL}/${request.campaignId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveCampaignTemplate = async (
    request: SaveCampaignRequest,
  ): Promise<CampaignTemplate> => {
    try {
      const result = await client.post(CAMPAIGN_TEMPLATES_BASE_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteCampaignTemplate = async (
    templateId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${CAMPAIGN_TEMPLATES_BASE_URL}/${templateId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const copyCampaignTemplate = async (
    request: CloneCampaignRequest,
  ): Promise<CampaignTemplate> => {
    try {
      const result = await client.post(
        `${CAMPAIGN_TEMPLATES_BASE_URL}/${request.campaignId}/copy`,
        { account: request.account },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const cloneCampaign = async (
    request: CloneCampaignRequest,
  ): Promise<CampaignTemplate> => {
    try {
      const result = await client.post(
        `${CAMPAIGN_TEMPLATES_BASE_URL}/${request.campaignId}/clone`,
        { account: request.account },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getCampaignTemplates,
    getCampaignTemplate,
    updateCampaignTemplate,
    saveCampaignTemplate,
    deleteCampaignTemplate,
    copyCampaignTemplate,
    cloneCampaign,
  };
};
