import { handleAxiosError, isIn } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  Campaign,
  CampaignActionsEnum,
  CampaignFormValues,
  CampaignTemplate,
} from '@/client/campaigns';
import { Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { CampaignForm } from '@/components/campaigns/forms';
import { StateProvider } from '@/components/state-provider';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useCampaign,
  useCampaignAction,
  useUpdateCampaign,
} from '@/hooks/query';
import {
  useCampaignTemplate,
  useUpdateCampaignTemplate,
} from '@/hooks/query/campaign-templates.hook';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { ToastType, useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getCampaignSubmitValueByStep,
  getCampaignTemplateSubmitValueByStep,
  partialRequests,
  prepareRequestData,
  queryStateConverter,
  setCampaignInitialValues,
  setCampaignTemplateInitialValues,
} from '@/utils/helpers';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { processIncludeExcludeTargetEntities } from '@/utils/targets';
import { AxiosError } from 'axios';
import { use } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const UpdateCampaignPage = () => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();
  const toast = useToast();
  const { id } = useParams();
  const { state } = useLocation();
  const isTemplate = !!state?.isTemplate;
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);
  const [currentCampaign, setCurrentCampaign] = useState<Campaign | null>(null);
  const { campaign, isLoading: isCampaignLoading } = useCampaign({
    campaignId: id,
    enabled: !isTemplate,
  });

  const { campaignTemplate, isLoading: isCampaignTemplateLoading } =
    useCampaignTemplate({
      campaignId: id,
      enabled: isTemplate,
    });

  const [activeStep, setActiveStep] = useState(state?.activeStep ?? 0);
  const [initialValues, setInitialValues] = useState<
    CampaignFormValues | undefined
  >(undefined);

  useEffect(() => {
    if ((isTemplate && !campaignTemplate) || (!isTemplate && !campaign)) return;

    if (isTemplate && !isCampaignTemplateLoading) {
      setCurrentCampaign(campaignTemplate as Campaign);
    } else if (!isTemplate && !isCampaignLoading) {
      setCurrentCampaign(campaign as Campaign);
    }
  }, [campaign, campaignTemplate]);

  useEffect(() => {
    if (!currentCampaign) return;
    const fillValues = async () => {
      try {
        const result = await partialRequests(
          [isIn('campaigns', [currentCampaign.id])],
          client.users.getUsers,
        );

        const { includedEntities, excludedEntities } =
          processIncludeExcludeTargetEntities({
            target: currentCampaign.targets,
            excludes: currentCampaign.excludes,
            account: account as Account,
          });

        setInitialValues(
          isTemplate
            ? setCampaignTemplateInitialValues(
                currentCampaign as CampaignTemplate,
              )
            : setCampaignInitialValues(
                currentCampaign,
                result,
                canUseFeature(Subjects.PHISHING_CAMPAIGN_TARGET_SELECTOR),
                includedEntities,
                excludedEntities,
              ),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    };

    fillValues();
  }, [currentCampaign]);

  useEffect(() => {
    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({
        [HubspotProperty.PHISHING_CAMPAIGN_VIEW]: HubspotValue.YES,
      });
    }
  }, []);

  const handleOnStepChange = (step: number) => setActiveStep(step);

  return (
    <StateProvider
      state={(campaign || campaignTemplate) && initialValues}
      isLoading={
        isCampaignLoading || isCampaignTemplateLoading || !initialValues
      }
      loadingMessage={t('campaign.loading.data')}
      errorMessage={t('campaign.loading.data.failed')}
    >
      <UpdateCampaignWithState
        campaign={(campaign || campaignTemplate) as Campaign}
        initialValues={initialValues as CampaignFormValues}
        setInitialValues={setInitialValues}
        onStepChange={handleOnStepChange}
        activeStep={activeStep}
        isTemplate={isTemplate}
      />
    </StateProvider>
  );
};

export const UpdateCampaignWithState: React.FC<{
  campaign: Campaign;
  initialValues: CampaignFormValues;
  setInitialValues: (values: CampaignFormValues) => void;
  activeStep: number;
  onStepChange: (step: number) => void;
  isTemplate: boolean;
}> = ({
  campaign,
  initialValues,
  setInitialValues,
  onStepChange,
  activeStep,
  isTemplate,
}) => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();
  const navigate = useNavigate();
  const toast = useToast();

  const account = useAppSelector(selectCurrentAccount);

  const {
    lastMessage,
    setNotificationParam: setCampaignId,
    notificationParam: notificationCampaignId,
    isConnected,
  } = useNotifications(client.campaigns.campaignsNotifyUrl);
  const updateCampaign = useUpdateCampaign();
  const updateCampaignTemplate = useUpdateCampaignTemplate();
  const campaignAction = useCampaignAction();

  const [launchState, setLaunchState] = useState<
    typeof LoadingStatuses.IDLE | typeof LoadingStatuses.LOADING
  >(LoadingStatuses.IDLE);

  useEffect(() => {
    if (!notificationCampaignId) return;
    handleNotifyCampaignLaunch(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyCampaignLaunch = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'campaign.create.sync.finished',
        'campaign.create.sync.failed',
      ].includes(messages?.data?.event)
    )
      return;

    const statuses: {
      [key: string]: [ToastType | undefined, string, string];
    } = {
      'campaign.create.sync.finished': [
        toast?.success,
        'toast.success',
        'campaign.success.launch',
      ],
      'campaign.create.sync.failed': [
        toast?.info,
        'toast.info',
        'campaign.fail.launch',
      ],
    };

    const [callback, title, description] = statuses[messages?.data?.event];

    callback && callback(t(title), t(description));

    setLaunchState(LoadingStatuses.IDLE);
    setCampaignId(undefined);

    navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS]());
  };

  const pathItems = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.edit.name', { name: campaign?.name }),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](
        campaign?.id as string,
      ),
      template: AppBreadCrumbTemplate,
    },
  ];

  const handleSave = async (data: CampaignFormValues) => {
    const payload = prepareRequestData(account?.id as string, data);
    const partialPayload = isTemplate
      ? getCampaignTemplateSubmitValueByStep(activeStep, payload)
      : getCampaignSubmitValueByStep(
          activeStep,
          payload,
          canUseFeature(Subjects.PHISHING_CAMPAIGN_TARGET_SELECTOR),
        );

    const service = isTemplate ? updateCampaignTemplate : updateCampaign;
    const response = await service.update({
      campaignId: campaign.id,
      updates: partialPayload,
    });

    if (isTemplate) {
      setInitialValues(
        setCampaignTemplateInitialValues(response as CampaignTemplate),
      );
    }
  };

  const handleLaunchCampaign = async (data: CampaignFormValues) => {
    const payload = prepareRequestData(account?.id as string, data);
    const partialPayload = isTemplate
      ? getCampaignTemplateSubmitValueByStep(activeStep, payload)
      : getCampaignSubmitValueByStep(
          activeStep,
          payload,
          canUseFeature(Subjects.PHISHING_CAMPAIGN_TARGET_SELECTOR),
        );

    await updateCampaign.update({
      campaignId: campaign.id,
      updates: partialPayload,
    });

    await campaignAction.updateStatus({
      campaignId: campaign.id,
      action: CampaignActionsEnum.LAUNCH,
    });
  };

  const handleSubmit = async (data: CampaignFormValues) => {
    setCampaignId(campaign?.id);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current) {
        setLaunchState(LoadingStatuses.LOADING);
        await handleLaunchCampaign(data);
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <FlexContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      minHeight="100%"
    >
      <AppBreadCrumb
        model={pathItems}
        data-testid="update-campaign-bread-crumb"
        className="flex-initial"
      />
      <CampaignForm
        initialValues={initialValues}
        title={t('campaign.update')}
        onSubmit={handleSubmit}
        onSave={handleSave}
        launchState={launchState}
        saveState={queryStateConverter(updateCampaign)}
        campaignId={campaign.id}
        onStepChange={onStepChange}
        activeStep={activeStep}
        isTemplate={isTemplate}
      />
    </FlexContainer>
  );
};
