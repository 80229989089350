import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account, AccountAPIConfig } from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { AccountAPIForm } from '@/components/accounts/forms/AccountAPIForm';
import { useAccount } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { ImportantIcon } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountHeader } from './AccountHeader';

const StyledPasswordDialog = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--yellow-opacity);
  border-radius: var(--medium-border-radius);
  padding: var(--default-padding) var(--big-padding);
  flex: 1 1 50%;
  margin-bottom: var(--medium-padding);

  p {
    margin: 0;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    margin-left: var(--small-padding);
  }
`;

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account,
  can: (action: Actions, subject: Subjects) => boolean,
  t: TranslationFunctionType,
): MenuItem[] => {
  const pathItems: MenuItem[] = [];

  pathItems.push({
    label: currentAccount?.name,
    url: !currentAccount.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  });

  if (
    currentAccount?.isSystem &&
    account &&
    can(Actions.READ, Subjects.ACCOUNTS)
  ) {
    pathItems.push(
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
      {
        label: account?.name,
        url: !currentAccount?.isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
        className: 'active',
        template: AppBreadCrumbTemplate,
      },
    );
  }

  pathItems.push({
    label: t('api'),
    url: RedirectPaths[RedirectPathsEnum.ACCOUNT_API](
      account ? account?.id : currentAccount?.id,
    ),
    template: AppBreadCrumbTemplate,
  });

  return pathItems;
};

export const AccountAPIPage: React.FC = () => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const toast = useToast();
  const { id: accountId } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { account } = useAccount({ accountId });
  const [initialValues, setInitialValues] = useState<AccountAPIConfig>({
    active: false,
    username: '',
    password: '',
    clientId: '',
    clientSecret: '',
    authenticationUrl: '',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const currentAccountId = accountId || currentAccount?.id;

    if (currentAccountId) {
      getAccountAPIClient(currentAccountId);
    }
  }, [accountId, currentAccount]);

  const getAccountAPIClient = async (accountId: string) => {
    try {
      const response = await client.accounts.getAccountAPIClient(accountId);
      setInitialValues(response);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleUpdateAccountAPIClient = async (
    data: FormikValues,
    regenerate?: boolean,
  ) => {
    const currentAccountId = accountId || currentAccount?.id;

    if (!currentAccountId) return;

    const payload = regenerate
      ? { active: initialValues.active, regenerate: true }
      : {
          active: data.active,
          regenerate: false,
        };

    try {
      setIsSubmitting(true);

      const response = await client.accounts.updateAccountAPIClient(
        currentAccountId,
        payload,
      );

      setInitialValues({
        ...response,
        active: data.active,
      });

      if (!regenerate) {
        toast?.success(
          t('toast.success'),
          data.active ? t('api.enable.success') : t('api.disable.success'),
        );
      } else {
        toast?.success(
          t('toast.success'),
          t('api.credentials.regenerate.success'),
        );
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setIsSubmitting(false);
    }
  };

  const PasswordDialog = () => (
    <StyledPasswordDialog>
      <FlexContainer justify="flex-start">
        <ImportantIcon />
        <p>{t('api.user.password.warning')}</p>
      </FlexContainer>
    </StyledPasswordDialog>
  );

  return (
    <>
      {!currentAccount || !account ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <FlexContainer direction="column" align="flex-start">
          <div className="w-full">
            <AppBreadCrumb
              model={getPathItems(
                account as Account,
                currentAccount as Account,
                can,
                t,
              )}
            />
            <AccountHeader selectedAccount={account} />

            <PasswordDialog />

            <AccountAPIForm
              accountId={accountId || currentAccount?.id}
              initialValues={initialValues}
              state={
                isSubmitting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
              }
              disabled={isSubmitting}
              onSubmit={handleUpdateAccountAPIClient}
              onRegenerate={() =>
                handleUpdateAccountAPIClient(initialValues, true)
              }
            />
          </div>
        </FlexContainer>
      )}
    </>
  );
};
