import {
  CampaignUserResultMessages,
  CampaignUserResultStatusesEnum,
  SenderProfileType,
} from '@/client/campaigns';
import {
  BranchStatusEnum,
  CourseStatusAttemptEnum,
  UserStatusEnum,
} from '@/client/courses';
import { TranslationFunctionType } from '@/common/types';
import { AppChip } from '@/ui/chip';
import { uniqueId } from 'lodash';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const determineAttemptStatusColor = (
  status: string,
  t: TranslationFunctionType,
) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return (
        <AppChip
          label={t('course.attempt.status.inProgress')}
          type="secondary"
        />
      );
    case CourseStatusAttemptEnum.COMPLETED:
      return (
        <AppChip label={t('course.attempt.status.completed')} type="primary" />
      );
    case CourseStatusAttemptEnum.FAILED:
      return <AppChip label={t('course.attempt.status.failed')} type="error" />;
    default:
      return (
        <AppChip label={t('course.attempt.status.enrolled')} type="secondary" />
      );
  }
};

export const determineScoreColor = (status: string, score: number) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return <AppChip label={score.toString()} type="secondary" />;
    case CourseStatusAttemptEnum.COMPLETED:
      return <AppChip label={score.toString()} type="primary" />;
    case CourseStatusAttemptEnum.FAILED:
      return <AppChip label={score.toString()} type="error" />;
    default:
      return <AppChip label={score.toString()} type="secondary" />;
  }
};

export const determineUserStatusColor = (
  status: string,
  t: TranslationFunctionType,
) => {
  switch (status) {
    case UserStatusEnum.IN_PROGRESS:
      return (
        <AppChip
          label={t('course.attempt.status.inProgress')}
          type="secondary"
        />
      );
    case UserStatusEnum.COMPLETED:
      return (
        <AppChip label={t('course.attempt.status.completed')} type="primary" />
      );
    case UserStatusEnum.ENROLLED:
      return (
        <AppChip label={t('course.attempt.status.enrolled')} type="secondary" />
      );
    case UserStatusEnum.NOT_SCHEDULED:
      return (
        <AppChip label={t('course.attempt.status.notScheduled')} type="error" />
      );
    case UserStatusEnum.SCHEDULED:
      return (
        <AppChip
          label={t('course.attempt.status.scheduled')}
          type="secondary"
        />
      );
    default:
      return null;
  }
};

export const determineBranchStatusColor = (
  status: string,
  t: TranslationFunctionType,
) => {
  switch (status) {
    case BranchStatusEnum.NOT_SCHEDULED:
      return (
        <AppChip label={t('course.attempt.status.notScheduled')} type="error" />
      );
    case BranchStatusEnum.SCHEDULED:
      return (
        <AppChip
          label={t('course.attempt.status.scheduled')}
          type="secondary"
        />
      );
    case BranchStatusEnum.ENROLLED:
      return (
        <AppChip label={t('course.attempt.status.enrolled')} type="secondary" />
      );
    default:
      return null;
  }
};

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const difficultyOptions = [
  { label: <>&#x1f41f;</>, value: 1 },
  { label: <>&#x1f41f;&#x1f41f;</>, value: 2 },
  { label: <>&#x1f41f;&#x1f41f;&#x1f41f;</>, value: 3 },
  { label: <>&#x1f41f;&#x1f41f;&#x1f41f;&#x1f41f;</>, value: 4 },
  { label: <>&#x1f41f;&#x1f41f;&#x1f41f;&#x1f41f;&#x1f41f;</>, value: 5 },
];

export const difficultyFilterOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];

export const displayDifficulty = (campaignDifficulty: number): ReactNode => {
  const option = difficultyOptions.find((x) => x.value === campaignDifficulty);
  return option?.label;
};

export const senderOptions = (t: TranslationFunctionType) => [
  { label: t('generic.internal'), value: SenderProfileType.INTERNAL },
  { label: t('generic.external'), value: SenderProfileType.EXTERNAL },
];

const StyledItems = styled.span`
  font-style: italic;
  color: var(--gray-darker);
  cursor: pointer;
`;

export const displayMiltipleItems = (
  nameString: string,
  t: TranslationFunctionType,
) => {
  const nameArr = nameString.split(',');
  const showItemNames = nameArr.slice(0, 3);
  const showMoreItems = nameArr.slice(3);
  const id = uniqueId('items');

  return nameArr.length <= 3 ? (
    nameArr.join(', ')
  ) : (
    <>
      <span>{showItemNames.join(', ')}</span>
      <StyledItems id={id}>
        {` ${t('generic.items.more.count', {
          count: showMoreItems.length,
        })}`}
      </StyledItems>
      <Tooltip
        position="mouse"
        target={`#${id}`}
        content={showMoreItems.join(', ')}
      />
    </>
  );
};

export const parseFloatingNumber = (number: number | undefined) =>
  number ? parseFloat(number.toFixed(2)) : 0;

const ErrorTag = styled(Tag)`
  &.p-tag {
    background-color: var(--gray-darker);
  }
`;

export const getCampaignReportsStatusSeverity = (
  status: CampaignUserResultStatusesEnum,
  t: (key: string) => string,
) => {
  switch (status) {
    case CampaignUserResultStatusesEnum.CAMPAIGN_CREATED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.CAMPAIGN_CREATED
            ],
          )}
          severity="success"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.EMAIL_SENT:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_SENT
            ],
          )}
          severity="success"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.EMAIL_OPENED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_OPENED
            ],
          )}
          severity="warning"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.CLICKED_LINK:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.CLICKED_LINK
            ],
          )}
          className="orange"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.SUBMITTED_DATA:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.SUBMITTED_DATA
            ],
          )}
          severity="danger"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.EMAIL_REPORTED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_REPORTED
            ],
          )}
          severity="success"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.ERROR:
      return (
        <ErrorTag
          value={t(
            CampaignUserResultMessages[CampaignUserResultStatusesEnum.ERROR],
          )}
          className="gray"
        ></ErrorTag>
      );
    case CampaignUserResultStatusesEnum.SCHEDULED:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.SCHEDULED
            ],
          )}
          severity="info"
        ></Tag>
      );
    case CampaignUserResultStatusesEnum.SENDING:
      return (
        <Tag
          value={t(
            CampaignUserResultMessages[CampaignUserResultStatusesEnum.SENDING],
          )}
          severity="info"
        ></Tag>
      );

    default:
      return null;
  }
};

const StyledTag = styled(Tag)`
  &.p-tag-info {
    background-color: var(--gray-dark);
  }
  .p-tag-icon {
    margin-right: auto;
  }
`;

export const getRepotedStatus = (reported: boolean) =>
  reported ? (
    <StyledTag icon="pi pi-check-circle" severity="success" />
  ) : (
    <StyledTag icon="pi pi-times-circle" severity="info" />
  );
