import { FiltersType } from '@/api/types';
import { client } from '@/client';
import {
  CampaignTemplate,
  CloneCampaignRequest,
  ListCampaignTemplatesResponse,
  SaveCampaignRequest,
  UpdateCampaignRequest,
} from '@/client/campaigns';
import { MessageResponseModel } from '@/client/models';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  CloneCampaignMutation,
  DeleteCampaignMutation,
  GenericForbiddenApiError,
  SaveCampaignMutation,
} from './types';

const queryKey = 'campaign-templates';
const singleQueryKey = 'campaign-template';

export const useCampaignTemplates = (
  params: UseQueryOptions & {
    withPreview?: 0 | 1;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListCampaignTemplatesResponse> & {
  campaignTemplates: ListCampaignTemplatesResponse | undefined;
} => {
  const {
    withPreview = 0,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.campaignTemplates.getCampaignTemplates(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    campaignTemplates: data,
    ...(rest as UseQueryResult<ListCampaignTemplatesResponse>),
  };
};

export const useCampaignTemplate = (
  params: UseQueryOptions & {
    campaignId?: string;
  },
): UseQueryResult<CampaignTemplate> & {
  campaignTemplate: CampaignTemplate | undefined;
} => {
  const {
    campaignId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, campaignId],
    () =>
      campaignId
        ? client.campaignTemplates.getCampaignTemplate(campaignId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<CampaignTemplate>),
    },
  );

  return {
    campaignTemplate: data,
    ...(rest as UseQueryResult<CampaignTemplate>),
  };
};

export const useUpdateCampaignTemplate = (): {
  update: (payload: UpdateCampaignRequest) => Promise<CampaignTemplate>;
} & SaveCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    CampaignTemplate,
    GenericForbiddenApiError,
    UpdateCampaignRequest
  >('updateCampaignTemplate', (payload: UpdateCampaignRequest) =>
    client.campaignTemplates.updateCampaignTemplate(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useSaveCampaignTemplate = (): {
  create: (payload: SaveCampaignRequest) => Promise<CampaignTemplate>;
} & SaveCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    CampaignTemplate,
    GenericForbiddenApiError,
    SaveCampaignRequest
  >('createCampaignTemplate', (payload: SaveCampaignRequest) =>
    client.campaignTemplates.saveCampaignTemplate(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useDeleteCampaignTemplate = (): {
  delete: (templateId: string) => Promise<MessageResponseModel>;
} & DeleteCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteCampaignTemplate', (templateId: string) =>
    client.campaignTemplates.deleteCampaignTemplate(templateId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useCopyCampaignTemplate = (): {
  copy: (payload: CloneCampaignRequest) => Promise<CampaignTemplate>;
} & CloneCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    CampaignTemplate,
    GenericForbiddenApiError,
    CloneCampaignRequest
  >('copyCampaignTemplate', (payload: CloneCampaignRequest) =>
    client.campaignTemplates.copyCampaignTemplate(payload),
  );

  return { copy: mutateAsync, ...(rest as any) };
};

export const useCloneCampaignTemplate = (): {
  clone: (payload: CloneCampaignRequest) => Promise<CampaignTemplate>;
} & CloneCampaignMutation => {
  const { mutateAsync, ...rest } = useMutation<
    CampaignTemplate,
    GenericForbiddenApiError,
    CloneCampaignRequest
  >('cloneCampaign', (payload: CloneCampaignRequest) =>
    client.campaignTemplates.cloneCampaign(payload),
  );

  return { clone: mutateAsync, ...(rest as any) };
};
