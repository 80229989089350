import { GroupCondition } from '@/api/enums';
import { equal, group, handleAxiosError, like, nested } from '@/api/helpers';
import {
  CampaignTemplate,
  CreateCampaignFromTemplateFormState,
} from '@/client/campaigns';
import { FilterNamesEnum } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { availableLanguagesDropdown } from '@/common/constants/languages';
import { TranslationFunctionType } from '@/common/types';
import {
  useCampaignTemplates,
  useCloneCampaignTemplate,
} from '@/hooks/query/campaign-templates.hook';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { Slider } from '@/ui/slider';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getPhishingSignOptions,
  getPhishingTypeOptions,
  ITEMS_PER_TEMPLATES_MODAL,
} from '@/utils/helpers';
import {
  difficultyFilterOptions,
  senderOptions,
} from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { Form, Formik, FormikValues } from 'formik';
import { debounce } from 'lodash';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DataTableFilters, FilterType, FilterTypeEnum } from '../tables/crud';
import { CampaignTemplatesList } from './CampaignTemplatesList';
import { PreviewCampaignTemplate } from './PreviewCampaignTemplate';

const StyledDialog = styled(Dialog)`
  &:not(.fullscreen) {
    @media screen and (min-width: 1133px) {
      width: 1132px;
    }
    @media screen and (max-width: 1132px) {
      width: 800px;
    }
    @media screen and (max-width: 800px) {
      width: 600px;
    }
    max-width: 1132px;
    height: 910px;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;

    .p-dialog-header {
      border-bottom: 1px solid var(--gray-dark);
      padding-bottom: var(--default-padding);
    }

    .p-dialog-content {
      padding: 0;
    }
  }

  .p-dialog-footer {
    border-top: 1px solid var(--gray-dark);
  }

  .p-dialog-header-icons {
    position: fixed;
    top: var(--default-padding);
    right: var(--default-padding);
  }
`;

const StyledName = styled.span`
  color: var(--red-main);
`;

const StyledAppChip = styled(AppChip)`
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: var(--red-dark);
  }
`;

export const CampaignTemplatesModal: React.FC<
  DialogProps & {
    preselectedCampaign?: CampaignTemplate;
    isCatalogue?: boolean;
  }
> = ({ onHide, visible, preselectedCampaign, isCatalogue }) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const [showList, setShowList] = useState<boolean>(!isCatalogue);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );
  const [initialValues, setInitialValues] =
    useState<CreateCampaignFromTemplateFormState>({
      selectedCampaignTemplate: preselectedCampaign,
    });

  const [data, setData] = useState<CampaignTemplate[]>([]);
  const [clearData, setClearData] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const {
    apiFilters,
    onFilter,
    skip,
    setSkip,
    onGridPageChange,
    onGridRowChange,
  } = useTable();

  const [search, setSearch] = useState('');
  const debouncedSearchValue = useMemo(
    () =>
      debounce((event: SyntheticEvent<HTMLInputElement>) => {
        setSearch((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );
  const {
    isLoading: areCampaignTemplatesLoading,
    campaignTemplates,
    refetch,
  } = useCampaignTemplates({
    take: ITEMS_PER_TEMPLATES_MODAL,
    skip,
    withPreview: 1,
    filters: [
      ...(apiFilters ? apiFilters : []),
      ...(search
        ? [
            group(GroupCondition.OR, [
              like('name', search),
              nested('sendingProfile', [like('name', search)]),
            ]),
          ]
        : []),
      equal('isPublished', true),
    ],
    enabled: visible,
  });

  useEffect(() => {
    setInitialValues({ selectedCampaignTemplate: preselectedCampaign });
  }, [preselectedCampaign]);

  useEffect(() => {
    if (areCampaignTemplatesLoading || !campaignTemplates) return;
    const fetchedData = campaignTemplates.result;
    const totalItems = campaignTemplates.count;

    if ((clearData || data?.length === 0) && !showList) {
      setInitialValues({ selectedCampaignTemplate: fetchedData[0] });
    }

    setHasMore(ITEMS_PER_TEMPLATES_MODAL + skip < totalItems);
    setData((prevData: CampaignTemplate[]) => [
      ...(clearData
        ? []
        : prevData.filter(
            (x) => !fetchedData.find((y: CampaignTemplate) => x.id === y.id),
          )),
      ...fetchedData,
    ]);
    setClearData(false);
  }, [campaignTemplates, areCampaignTemplatesLoading]);

  const cloneCampaign = useCloneCampaignTemplate();

  const handleCreateNewCampaignFromTemplate = async (data: FormikValues) => {
    try {
      if (!currentAccount || !data?.selectedCampaignTemplate) return;
      setIsLoading(LoadingStatuses.LOADING);

      const response = await cloneCampaign.clone({
        campaignId: data?.selectedCampaignTemplate?.id,
        account: currentAccount?.id,
      });

      setIsLoading(LoadingStatuses.IDLE);

      if (response && !response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isPublished: false },
      });
      toast?.success(t('toast.success'), t('campaign.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setIsLoading(LoadingStatuses.IDLE);
    }
  };

  const getFilters = (t: TranslationFunctionType): FilterType[] => [
    {
      label: t('generic.lang'),
      field: FilterNamesEnum.EMAIL_TEMPLATE_BY_LANGUAGE,
      placeholder: t('generic.lang.search'),
      type: FilterTypeEnum.MULTI_SELECT,
      options: availableLanguagesDropdown,
    },
    {
      label: t('generic.difficulty'),
      field: FilterNamesEnum.DIFFICULTY,
      placeholder: t('generic.select'),
      type: FilterTypeEnum.MULTI_SELECT,
      options: difficultyFilterOptions,
    },
    {
      label: t('campaign.template.phishingType'),
      field: FilterNamesEnum.CAMPAIGN_PHISHING_TYPE,
      placeholder: t('generic.select'),
      type: FilterTypeEnum.SELECT,
      options: getPhishingTypeOptions(t),
    },
    {
      label: t('campaign.template.phishingSign'),
      field: FilterNamesEnum.CAMPAIGN_PHISHING_SIGN,
      placeholder: t('generic.select'),
      type: FilterTypeEnum.SELECT,
      options: getPhishingSignOptions(t),
    },
    {
      label: t('generic.sender'),
      field: FilterNamesEnum.SENDER,
      type: FilterTypeEnum.SELECT,
      options: senderOptions(t),
      placeholder: t('generic.select'),
    },
  ];

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleCreateNewCampaignFromTemplate}
      >
        {({ values, setFieldValue, errors, submitForm }) => {
          const header = (
            <>
              {values.selectedCampaignTemplate ? (
                <h1>
                  {t('campaigns.fromTemplate')}:{' '}
                  <StyledName>
                    {values.selectedCampaignTemplate.name}
                  </StyledName>
                </h1>
              ) : (
                <h1>{t('campaigns.fromTemplate')}</h1>
              )}
              <FlexContainer
                justify="space-between"
                align="flex-start"
                wrap="wrap"
                gap={8}
                className="mt-4 w-full"
              >
                <DataTableFilters
                  filters={getFilters(t)}
                  onFilter={(values) => {
                    if (
                      !Object.values(values).includes(null) &&
                      Object.keys(values).length
                    ) {
                      setClearData(true);
                    }
                    onFilter(values);
                  }}
                  tableName={
                    !isCatalogue
                      ? TableNamesEnum.CAMPAIGNS_TEMPLATES_LIST
                      : undefined
                  }
                  className="flex-initial"
                />
                <div className="p-input-icon-left flex-auto min-w-300">
                  <InputText
                    className="w-full"
                    onInput={(e) => {
                      debouncedSearchValue(e);
                      setSkip(0);
                    }}
                    placeholder={t('templates.searchNameOrSender')}
                    autoComplete="off"
                  />
                  <i className="pi pi-search" />
                </div>
              </FlexContainer>
            </>
          );

          const loadMore = () => setSkip(skip + ITEMS_PER_TEMPLATES_MODAL);

          const footer = (
            <FlexContainer width="100%" justify="flex-start" gap={24}>
              {showList ? (
                <AppButton
                  label={t('generic.preview')}
                  severity="secondary"
                  onClick={() => setShowList(false)}
                  isDisabled={
                    !values.selectedCampaignTemplate ||
                    !!Object.keys(errors).length
                  }
                />
              ) : (
                <Slider>
                  <FlexContainer
                    gap={8}
                    justify="flex-start"
                    align="flex-start"
                    flex="1 1 80%"
                  >
                    {!!data?.length &&
                      data.map((campaignTemplate: CampaignTemplate) => (
                        <StyledAppChip
                          key={campaignTemplate.id}
                          label={campaignTemplate.name}
                          type="error"
                          onClick={() => {
                            setFieldValue(
                              'selectedCampaignTemplate',
                              campaignTemplate,
                            );
                          }}
                        />
                      ))}
                    {hasMore && !!data.length && (
                      <StyledAppChip
                        label={t('generic.loadMore')}
                        icon={
                          areCampaignTemplatesLoading
                            ? 'pi pi-spin pi-spinner'
                            : 'pi pi-plus'
                        }
                        type="error"
                        className="min-w-max"
                        onClick={loadMore}
                      />
                    )}
                  </FlexContainer>
                </Slider>
              )}
              <FlexContainer
                justify="flex-end"
                className="mt-auto pb-2 min-w-max"
                wrap="wrap"
                flex="1 1 15%"
              >
                <AppButton
                  label={t('generic.close')}
                  severity="secondary"
                  type="outlined"
                  onClick={() => {
                    if (showList || preselectedCampaign) {
                      onHide();
                      setFieldValue('selectedCampaignTemplate', undefined);
                      setSearch('');
                    } else {
                      setShowList(true);
                      setSkip(0);
                    }
                  }}
                  className="mr-3"
                />
                {can(Actions.CREATE, Subjects.CAMPAIGNS) && (
                  <AppButton
                    label={t('campaign.create')}
                    onClick={submitForm}
                    state={isLoading}
                    isDisabled={
                      !values.selectedCampaignTemplate ||
                      !!Object.keys(errors).length
                    }
                  />
                )}
              </FlexContainer>
            </FlexContainer>
          );

          return (
            <Form id="campaign-template-form-container">
              <StyledDialog
                blockScroll
                visible={visible}
                header={header}
                footer={footer}
                onHide={onHide}
                draggable={false}
                className={!showList ? 'fullscreen' : ''}
                maximized={!showList}
              >
                {areCampaignTemplatesLoading ? (
                  <FlexContainer direction="column" className="mt-5">
                    <ProgressSpinner />
                    <h3>{t('campaign.template.email.loading')}</h3>
                  </FlexContainer>
                ) : data.length === 0 ? (
                  <FlexContainer direction="column" className="mt-5">
                    <h3>{t('campaign.template.email.empty')}</h3>
                  </FlexContainer>
                ) : showList && !!campaignTemplates?.result.length ? (
                  <CampaignTemplatesList
                    values={values}
                    setFieldValue={setFieldValue}
                    campaigns={campaignTemplates}
                    refetch={refetch}
                    skip={skip}
                    onRowsChange={onGridRowChange}
                    onPageChange={onGridPageChange}
                    count={campaignTemplates.count}
                  />
                ) : (
                  <PreviewCampaignTemplate
                    campaignId={values.selectedCampaignTemplate?.id}
                  />
                )}
              </StyledDialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
