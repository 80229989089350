import { AccountAPIConfig } from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { FormikInput, FormikWrappedSwitch } from '@/components/form';
import { useToast } from '@/hooks/useToast';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { Password } from 'primereact/password';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  .field ~ button {
    margin-top: 12px;
  }
`;

type AccountAPIFormFormProps = {
  initialValues: AccountAPIConfig;
  onSubmit: (data: FormikValues) => void;
  onRegenerate: () => void;
  state?: LoadingStateType;
  accountId?: string;
  disabled?: boolean;
};
export const AccountAPIForm: React.FC<AccountAPIFormFormProps> = ({
  initialValues,
  onSubmit,
  onRegenerate,
  state,
  accountId,
  disabled,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);

  const copyValue = async (value?: string) => {
    if (!value) return;
    await navigator.clipboard.writeText(value);

    toast?.success(t('api.field.copied'), t('api.field.copy.success'));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <FormContainer width={100}>
          <div className="w-full">
            <StyledForm>
              <h3>{t('api.configuration')}</h3>
              <FlexContainer gap={24} align-items="flex-end">
                <FlexContainer width="50%">
                  <CheckboxesContainer className="toggle-container mb-0">
                    <Field
                      inputId="active"
                      name="active"
                      label={t('generic.enable')}
                      value={values?.active}
                      component={FormikWrappedSwitch}
                      disabled={disabled}
                      onChange={(e: InputSwitchChangeEvent) =>
                        setDialogData({
                          show: true,
                          type: 'confirmation',
                          header: e.target.value
                            ? t('api.confirm.enable')
                            : t('api.confirm.disable'),
                          message: e.target.value
                            ? t('api.confirm.enable.message')
                            : t('api.confirm.disable.message'),
                          onAccept: () => onSubmit({ active: e.target.value }),
                        })
                      }
                    />
                  </CheckboxesContainer>
                </FlexContainer>
                <FlexContainer width="50%">
                  <div className="field w-full">
                    <Field
                      id="accountId"
                      name="accountId"
                      label={t('generic.accountId')}
                      value={accountId}
                      className="w-full"
                      component={FormikInput}
                      disabled
                    />
                  </div>
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3"
                    onClick={() => copyValue(accountId)}
                  />
                </FlexContainer>
              </FlexContainer>

              <h3>{t('api.auth')}</h3>
              <FlexContainer
                gap={24}
                align-items="flex-end"
                justify="flex-start"
              >
                <FlexContainer width="50%">
                  <div className="field w-full">
                    <Field
                      inputId="authenticationUrl"
                      name="authenticationUrl"
                      label={t('api.auth.url')}
                      className="w-full"
                      value={values?.authenticationUrl}
                      component={FormikInput}
                      disabled
                    />
                  </div>
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3 mr-3"
                    onClick={() => copyValue(values.authenticationUrl)}
                  />
                </FlexContainer>
              </FlexContainer>

              <h3>{t('api.user')}</h3>
              <FlexContainer gap={24} align-items="flex-end">
                <FlexContainer width="50%">
                  <div className="field w-full">
                    <Field
                      inputId="user"
                      name="user"
                      label={t('user')}
                      className="w-full"
                      value={values?.username}
                      component={FormikInput}
                      disabled
                    />
                  </div>
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3 mr-3"
                    onClick={() => copyValue(values.username)}
                  />
                </FlexContainer>
                <FlexContainer width="50%">
                  <div className="field w-full">
                    <label>{t('generic.password')}</label>
                    <Password
                      id="password"
                      name="password"
                      type="password"
                      className="w-full"
                      value={values?.password}
                      toggleMask
                      disabled
                      onChange={(e) =>
                        setFieldValue('password', e.target.value)
                      }
                    />
                  </div>
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3 mr-3"
                    onClick={() => copyValue(values.password)}
                  />
                </FlexContainer>
              </FlexContainer>

              <h3>{t('api.client')}</h3>
              <FlexContainer gap={24} align-items="flex-end">
                <FlexContainer width="50%">
                  <div className="field w-full">
                    <Field
                      inputId="clientId"
                      name="clientId"
                      label={t('api.clientId')}
                      className="w-full"
                      value={values?.clientId}
                      component={FormikInput}
                      disabled
                    />
                  </div>
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3 mr-3"
                    onClick={() => copyValue(values.clientId)}
                  />
                </FlexContainer>
                <FlexContainer width="50%">
                  <div className="field w-full">
                    <label>{t('api.clientSecret')}</label>
                    <Password
                      id="clientSecret"
                      name="clientSecret"
                      type="clientSecret"
                      className="w-full"
                      value={values?.clientSecret}
                      toggleMask
                      disabled
                      onChange={(e) =>
                        setFieldValue('clientSecret', e.target.value)
                      }
                    />
                  </div>
                  <AppButton
                    icon="pi pi-copy"
                    type="text"
                    className="ml-3 mr-3"
                    onClick={() => copyValue(values?.clientSecret)}
                  />
                </FlexContainer>
              </FlexContainer>
              <AppButton
                severity="secondary"
                className="ml-3"
                label={t('api.regenerate')}
                isDisabled={!values?.active || disabled}
                state={state}
                onClick={() =>
                  setDialogData({
                    show: true,
                    type: 'confirmation',
                    header: t('api.confirm.credentials.regenerate'),
                    message: t('api.confirm.credentials.regenerate.message'),
                    onAccept: onRegenerate,
                  })
                }
              />
            </StyledForm>
          </div>
        </FormContainer>
      )}
    </Formik>
  );
};
