import { MaterialType } from '@/client/courses';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { updateMaterialByTypePath } from '@/utils/helpers';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type CoursesTabsProps = {
  courseId: string;
  type: MaterialType;
};

const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
  (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': current.includes(url),
      })}
      role="menuitem"
    >
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );

export const CoursesTabs: React.FC<CoursesTabsProps> = ({ courseId, type }) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const location = useLocation();
  const account = useAppSelector(selectCurrentAccount);
  const isMaterial = type !== MaterialType.COURSE;
  const isResource = type === MaterialType.RESOURCE;
  const isPoster = type === MaterialType.POSTER;
  const isCustomMaterial = type === MaterialType.CUSTOM_MATERIAL;

  const items: MenuItem[] = [
    {
      label: t('generic.details'),
      template: getItemTemplate(
        isMaterial
          ? updateMaterialByTypePath(type, courseId)
          : RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? updateMaterialByTypePath(type, courseId)
        : RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](courseId),
    },
  ];

  if (!isMaterial) {
    items.push({
      label: t('course.scorm.preview'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.COURSES_SCORM_PREVIEW](courseId),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.COURSES_SCORM_PREVIEW](courseId),
    });
  }

  if (isCustomMaterial) {
    items.push({
      label: t('material.preview'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.CUSTOM_MATERIALS_PREVIEW](courseId),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.CUSTOM_MATERIALS_PREVIEW](courseId),
    });
  }

  if (account?.isSystem) {
    if (!isPoster) {
      items.push({
        label: t('generic.schedule'),
        template: getItemTemplate(
          isMaterial
            ? RedirectPaths[RedirectPathsEnum.RESOURCES_SCHEDULE](courseId)
            : RedirectPaths[RedirectPathsEnum.COURSES_SCHEDULE](courseId),
          location.pathname,
        ),
        data: isMaterial
          ? RedirectPaths[RedirectPathsEnum.RESOURCES_SCHEDULE](courseId)
          : RedirectPaths[RedirectPathsEnum.COURSES_SCHEDULE](courseId),
      });
    }

    if (
      can(Actions.READ, Subjects.COURSE_ACCOUNT_SCHEDULES) &&
      !isCustomMaterial
    ) {
      items.push({
        label: t('accounts'),
        template: getItemTemplate(
          isResource
            ? RedirectPaths[RedirectPathsEnum.RESOURCES_ACCOUNTS](courseId)
            : isPoster
            ? RedirectPaths[RedirectPathsEnum.POSTER_ACCOUNTS](courseId)
            : RedirectPaths[RedirectPathsEnum.COURSES_ACCOUNTS](courseId),
          location.pathname,
        ),
        data: isResource
          ? RedirectPaths[RedirectPathsEnum.RESOURCES_ACCOUNTS](courseId)
          : isPoster
          ? RedirectPaths[RedirectPathsEnum.POSTER_ACCOUNTS](courseId)
          : RedirectPaths[RedirectPathsEnum.COURSES_ACCOUNTS](courseId),
      });
    }
  }

  if (account?.isSystem && !isMaterial) {
    items.push({
      label: t('analytics'),
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.COURSES_ANALYTICS](courseId),
        location.pathname,
      ),
      data: RedirectPaths[RedirectPathsEnum.COURSES_ANALYTICS](courseId),
    });
  }
  if (
    !account?.isSystem &&
    can(Actions.UPDATE, Subjects.COURSE_USER_SCHEDULES) &&
    !isPoster
  ) {
    items.push({
      label: t('users'),
      template: getItemTemplate(
        isMaterial
          ? RedirectPaths[
              isResource
                ? RedirectPathsEnum.RESOURCES_USERS
                : RedirectPathsEnum.CUSTOM_MATERIALS_USERS
            ](courseId)
          : RedirectPaths[RedirectPathsEnum.COURSES_USERS](courseId),
        location.pathname,
      ),
      data: isMaterial
        ? RedirectPaths[
            isResource
              ? RedirectPathsEnum.RESOURCES_USERS
              : RedirectPathsEnum.CUSTOM_MATERIALS_USERS
          ](courseId)
        : RedirectPaths[RedirectPathsEnum.COURSES_USERS](courseId),
    });
  }
  const paths = items.map((item) => item.data);
  const currentPath = location.pathname;

  const [activeIndex, setActiveIndex] = useState<number>(
    paths.indexOf(currentPath),
  );

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
      className="mt-5 flex-shrink-0"
    />
  );
};
