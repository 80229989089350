import { processListingParams } from '@/api/helpers';
import {
  Account,
  AccountAPIConfig,
  AccountSendTestEmailRequest,
  AccountSSOConfig,
  ImportSSOConfigRequest,
  ListAccountsRequest,
  ListAccountsResponse,
  ListDuplicatedUsersRequest,
  ListDuplicatedUsersResponse,
  SaveAccountRequest,
  SubdomainAccountIdResponse,
  UpdateAccountAPIClientRequest,
  UpdateAccountRequest,
  UpdateSSOConfigRequest,
} from '@/client/accounts';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const accountsService = (client: AxiosInstance) => {
  const ACCOUNTS_BASE_URL = '/accounts';
  const SUBDOMAINS_BASE_URL = '/subdomains';

  const getAccounts = async (
    params: ListAccountsRequest,
  ): Promise<ListAccountsResponse> => {
    try {
      const result = await client.get<
        ListAccountsResponse,
        AxiosResponse<ListAccountsResponse>
      >(ACCOUNTS_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getAccount = async (accountId?: string): Promise<Account> => {
    try {
      const result = await client.get<Account, AxiosResponse<Account>>(
        `${ACCOUNTS_BASE_URL}/${accountId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAccount = async (request: SaveAccountRequest): Promise<Account> => {
    try {
      const result = await client.post<Account, AxiosResponse<Account>>(
        ACCOUNTS_BASE_URL,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateAccount = async (
    request: UpdateAccountRequest,
  ): Promise<Account> => {
    try {
      const result = await client.patch<Account, AxiosResponse<Account>>(
        `${ACCOUNTS_BASE_URL}/${request.accountId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteAccount = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNTS_BASE_URL}/${accountId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const forceAccountSync = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`efront-integration/sync/account/${accountId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getDuplicatedUsers = async (
    params: ListDuplicatedUsersRequest,
    accountId?: string,
  ): Promise<ListDuplicatedUsersResponse> => {
    try {
      const result = await client.get<
        ListDuplicatedUsersResponse,
        AxiosResponse<ListDuplicatedUsersResponse>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/users/migration`, {
        withCredentials: true,
        params: processListingParams(params),
      });
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const accountsNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/notify`;

  const getSubdomain = async (
    subdomain: string,
  ): Promise<SubdomainAccountIdResponse> => {
    try {
      const result = await client.get<
        SubdomainAccountIdResponse,
        AxiosResponse<SubdomainAccountIdResponse>
      >(`${SUBDOMAINS_BASE_URL}/${subdomain}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const sendTestEmail = async (
    request: AccountSendTestEmailRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${request.accountId}/email-settings/test-email`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const importUsers = async (
    accountId: string,
    fileId: string,
    importKey: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/users/import`,
        { fileId, importKey },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw e as AxiosError;
    }
  };

  const getSSOConfig = async (
    accountId?: string,
  ): Promise<AccountSSOConfig> => {
    try {
      const result = await client.get<
        AccountSSOConfig,
        AxiosResponse<AccountSSOConfig>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/sso`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const importSSOConfig = async (
    request: ImportSSOConfigRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${request.accountId}/sso/import`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateSSOConfig = async (
    request: UpdateSSOConfigRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.patch<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${request.accountId}/sso`,
        omit(request, 'accountId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getAccountAPIClient = async (
    accountId?: string,
  ): Promise<AccountAPIConfig> => {
    try {
      const result = await client.get<
        AccountAPIConfig,
        AxiosResponse<AccountAPIConfig>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/client`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateAccountAPIClient = async (
    accountId: string,
    request: UpdateAccountAPIClientRequest,
  ): Promise<AccountAPIConfig> => {
    try {
      const result = await client.patch<
        AccountAPIConfig,
        AxiosResponse<AccountAPIConfig>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/client`, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const ssoNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/sso/notify`;

  return {
    getAccounts,
    getAccount,
    saveAccount,
    updateAccount,
    deleteAccount,
    forceAccountSync,
    accountsNotifyUrl,
    getSubdomain,
    sendTestEmail,
    importUsers,
    getSSOConfig,
    importSSOConfig,
    updateSSOConfig,
    ssoNotifyUrl,
    getDuplicatedUsers,
    getAccountAPIClient,
    updateAccountAPIClient,
  };
};
