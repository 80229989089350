import { LanguagesEnum } from '@/api/enums';
import { Account, AccountADFormValues } from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import { azureAdSchema } from '@/components/accounts/forms/validations/azure-ad';
import { FormikCheckbox, FormikInput } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { AzureADGroupSelection } from '../AzureADGroupSelection';

type AzureADFormProps = {
  initialValues: AccountADFormValues;
  onSubmit: (data: AccountADFormValues) => void;
  onSync: () => void;
  account: Account;
  state?: LoadingStateType;
  syncState?: LoadingStateType;
  disabled?: boolean;
};
export const AzureADForm: React.FC<AzureADFormProps> = ({
  initialValues,
  onSubmit,
  onSync,
  account,
  state,
  syncState,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={azureAdSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, touched, setFieldTouched }) => {
        const [cantSync, setCantSync] = useState(false);
        useEffect(() => {
          const initialValuesWithoutActive = { ...initialValues, active: null };
          const valuesWithoutActive = { ...values, active: null };
          setCantSync(
            JSON.stringify(initialValuesWithoutActive) !==
              JSON.stringify(valuesWithoutActive),
          );
        }, [Object.values(values)]);

        return (
          <FormContainer width={100}>
            <Form className="w-full">
              <FlexContainer
                gap={40}
                width="100%"
                justify="space-between"
                align="flex-start"
                wrap="wrap"
              >
                <div className="flex-1">
                  <div className="field w-full">
                    <Field
                      id="clientId"
                      name="clientId"
                      label={t('azure.clientId')}
                      className="w-full"
                      value={values.clientId}
                      component={FormikInput}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="clientSecret"
                      name="clientSecret"
                      label={t('azure.clientSecret')}
                      className="w-full"
                      value={values.clientSecret}
                      component={FormikInput}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full">
                    <Field
                      id="tenantId"
                      name="tenantId"
                      label={t('azure.tenantId')}
                      className="w-full"
                      value={values.tenantId}
                      component={FormikInput}
                      required
                      disabled={disabled}
                    />
                  </div>
                  {!disabled && (
                    <AppButton
                      isSubmit
                      severity="secondary"
                      label={t('button.save')}
                      className="w-2 min-w-min mt-4 mb-5"
                      state={state}
                      isDisabled={!!Object.keys(errors).length || disabled}
                    />
                  )}
                </div>
                <div className="flex-1">
                  <CheckboxesContainer>
                    <div className="field-checkbox mb-0">
                      <span id="toggle-wrapper">
                        <Field
                          inputId="active"
                          name="active"
                          label={t('azure.entra.adSync')}
                          value={values.active}
                          component={FormikCheckbox}
                          disabled={
                            !account?.meta?.isMigratedOutsideOfEFront ||
                            disabled
                          }
                        />
                        {!account?.meta?.isMigratedOutsideOfEFront && (
                          <Tooltip
                            position="mouse"
                            target="#toggle-wrapper"
                            content={
                              !initialValues.isMigratedOutsideOfEFront
                                ? t('azure.sync.disabled.notMigrated.tooltip')
                                : t('azure.sync.disabled.notConfigured.tooltip')
                            }
                          />
                        )}
                      </span>
                    </div>
                    {values.active && (
                      <>
                        {Object.keys(values.mapping).map((key) => (
                          <AzureADGroupSelection
                            key={key}
                            index={key}
                            values={values.mapping}
                            adGroup={values.mapping[key]}
                            account={account}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            setFieldTouched={setFieldTouched}
                            disabled={disabled}
                          />
                        ))}

                        <FlexContainer align="flex-end" justify="space-between">
                          <div>
                            <AppButton
                              icon="pi pi-plus"
                              label={t('azure.newGroup')}
                              severity="secondary"
                              className="white-background"
                              onClick={() =>
                                setFieldValue('mapping', {
                                  ...values.mapping,
                                  [v4()]: {
                                    groupId: '',
                                    account: account,
                                    branches: [],
                                    groups: [],
                                    language: LanguagesEnum.EN,
                                  },
                                })
                              }
                              isDisabled={disabled}
                            />
                          </div>
                          {initialValues.hasAd && !disabled && (
                            <AppButton
                              icon="pi pi-sync"
                              type="outlined"
                              label={t('azure.force.sync')}
                              className="mt-4 ml-3"
                              onClick={onSync}
                              state={syncState}
                              isDisabled={cantSync || disabled}
                            />
                          )}
                        </FlexContainer>
                      </>
                    )}
                  </CheckboxesContainer>
                </div>
              </FlexContainer>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
