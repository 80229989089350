import { LanguagesEnum } from '@/api/enums';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { EmailTemplate } from '@/client/email-templates';
import { Group } from '@/client/groups';
import { PageTemplate } from '@/client/page-templates';
import { SendingProfile } from '@/client/sender-profiles';
import { User } from '@/client/users';
import { TargetEntities } from '@/common/types';
import {
  CampaignPhishingSignEnum,
  CampaignPhishingTypeEnum,
} from './campaigns.type';

export type CampaignSummary = {
  total: number;
  emailsSent: number;
  emailsOpen: number;
  clickedLinks: number;
  submittedData: number;
  emailsReported: number;
  errors: number;
};

export type CampaignHistoryEventMeta = {
  payload: any;
  browser: any;
};

export type CampaignHistoryEvent = {
  id: string;
  time: Date;
  status: CampaignUserResultMessagesType;
  meta: CampaignHistoryEventMeta;
};

export enum CampaignStatusesEnum {
  ACTIVE = 'active',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  PENDING_LAUNCH = 'pending-launch',
  PENDING_COMPLETION = 'pending-completion',
  FAILED_LAUNCH = 'failed-launch',
  FAILED_COMPLETION = 'failed-completion',
}

export const CampaignStatusMessages = {
  [CampaignStatusesEnum.ACTIVE]: 'campaign.status.active',
  [CampaignStatusesEnum.DRAFT]: 'campaign.status.draft',
  [CampaignStatusesEnum.SCHEDULED]: 'campaign.status.scheduled',
  [CampaignStatusesEnum.COMPLETED]: 'campaign.status.completed',
  [CampaignStatusesEnum.PENDING_LAUNCH]: 'campaign.status.launch',
  [CampaignStatusesEnum.PENDING_COMPLETION]: 'campaign.status.complete',
  [CampaignStatusesEnum.FAILED_LAUNCH]: 'campaign.status.failLaunch',
  [CampaignStatusesEnum.FAILED_COMPLETION]: 'campaign.status.failComplete',
};

export enum CampaignUserResultStatusesEnum {
  CAMPAIGN_CREATED = 'campaign-created',
  EMAIL_SENT = 'email-sent',
  EMAIL_OPENED = 'email-opened',
  CLICKED_LINK = 'clicked-link',
  SUBMITTED_DATA = 'submitted-data',
  EMAIL_REPORTED = 'email-reported',
  ERROR = 'error',
  ERROR_SENDING_EMAIL = 'error-sending-email',
  SCHEDULED = 'scheduled',
  SENDING = 'sending',
}

export const CampaignUserResultMessages = {
  [CampaignUserResultStatusesEnum.CAMPAIGN_CREATED]:
    'campaign.event.status.created',
  [CampaignUserResultStatusesEnum.EMAIL_SENT]:
    'campaign.event.status.emailSent',
  [CampaignUserResultStatusesEnum.EMAIL_OPENED]:
    'campaign.event.status.emailOpened',
  [CampaignUserResultStatusesEnum.CLICKED_LINK]:
    'campaign.event.status.clickedLink',
  [CampaignUserResultStatusesEnum.SUBMITTED_DATA]:
    'campaign.event.status.submitted',
  [CampaignUserResultStatusesEnum.EMAIL_REPORTED]:
    'campaign.event.status.emailReported',
  [CampaignUserResultStatusesEnum.ERROR]: 'campaign.event.status.errorSending',
  [CampaignUserResultStatusesEnum.ERROR_SENDING_EMAIL]:
    'campaign.event.status.errorSending',
  [CampaignUserResultStatusesEnum.SCHEDULED]: 'campaign.event.status.scheduled',
  [CampaignUserResultStatusesEnum.SENDING]: 'campaign.event.status.sending',
};

export type CampaignUserResultMessagesType =
  keyof typeof CampaignUserResultMessages;

export type CampaignUserResultMeta = {
  id: string;
  position: string;
  ip: string;
  latitude: number;
  longitude: number;
  sendDate: Date;
  modifiedDate: Date;
  reported: boolean;
};

export type CampaignUserResult = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  status: CampaignUserResultStatusesEnum;
  events: CampaignHistoryEvent[];
  user: User;
  meta: CampaignUserResultMeta;
};

export type CampaignMeta = {
  encodeEmailTemplateImages: boolean;
  goPhishId: string;
  goPhishGroupId: string;
  jobId: string;
  origin: string;
  cloneOf: string;
  cloneOfName: string;
  phishingType?: CampaignPhishingTypeEnum;
  phishingSign?: CampaignPhishingSignEnum;
};

export type CampaignBenchmark = {
  clickedLinksPercentage: number;
  submittedDataPercentage: number;
  emailsReportedPercentage: number;
};

export enum CampaignType {
  CAMPAIGN = 'campaign',
  TEMPLATE = 'template',
}

export type Campaign = {
  id: string;
  name: string;
  created: Date;
  updated: Date;
  launchDate: Date;
  sendByDate: Date;
  completedDate: Date;
  emailTemplate: EmailTemplate;
  landingPage: PageTemplate;
  sendingProfile: SendingProfile;
  status: CampaignStatusesEnum;
  url: string;
  results: CampaignUserResult[];
  timeline: CampaignHistoryEvent[];
  account: Account;
  createdBy: User;
  useDynamicTarget: boolean | null;
  dynamicTargetSetAt: Date | null;
  users: User[];
  targets: TargetEntities;
  excludes: TargetEntities;
  branches: Branch[];
  groups: Group[];
  meta: CampaignMeta;
  type: CampaignType;
  summary: CampaignSummary;
  language?: LanguagesEnum;
  senderProfileType?: SenderProfileType;
  clickedLinksPercentage?: number;
  submittedDataPercentage?: number;
  description?: string;
  benchmarkSummary?: CampaignBenchmark;
  isAutoPhishing?: boolean;
  isWhitelisted?: boolean;
};

export type CampaignTemplate = {
  isPublished: boolean;
  difficulty?: number;
} & Campaign;

export enum SenderProfileType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export type CreateCampaignFromTemplateFormState = {
  selectedCampaignTemplate?: Campaign;
};
